import React, { useState } from 'react';

const Boxes = ({ product, updateProduct }) => {
  const [selectedMaterial, setSelectedMaterial] = useState('');
  const [customPrintingFeature, setCustomPrintingFeature] = useState('');
  const [customFeatures, setCustomFeatures] = useState([]);

  const boxTypeOptions = [
    { number: '1', name: 'Straight Tuck End Box (STE)' },
    { number: '2', name: 'Reverse Tuck End Box (RTE)' },
    { number: '3', name: 'Sleeve Box' },
    { number: '4', name: 'Counter Display Box' },
    { number: '5', name: 'Cylinder Box (Round Box)' },
    { number: '6', name: '1-2-3 Bottom Box' },
    { number: '7', name: 'Tuck Top Snap Lock Bottom Box' },
    { number: '8', name: 'Gable Box' },
    { number: '9', name: 'Display Box (With Steps)' },
    { number: '10', name: 'Display Box' },
    { number: '11', name: 'Shipping Boxes' },
    { number: '12', name: 'Tray Box' },
    { number: '13', name: 'Box with Lid' },
    { number: '14', name: 'Rigid Box (Lift-off Lid)' },
    { number: '15', name: 'Rigid Magnetic Closure Box' },
  ];

  const materialOptionsMap = {
    '1': ['White cardboard', 'Silver cardboard', 'Kraft paper', 'Corrugated paper', 'Hologram Cardboard'],
    '2': ['White cardboard', 'Silver cardboard', 'Kraft paper', 'Corrugated paper', 'Hologram Cardboard'],
    '3': ['White cardboard', 'Silver cardboard', 'Kraft paper', 'Corrugated paper', 'Hologram Cardboard'],
    '4': ['White cardboard', 'Silver cardboard', 'Kraft paper', 'Corrugated paper', 'Hologram Cardboard'],
    '5': ['Rigid cardboard'],
    '6': ['White cardboard', 'Silver cardboard', 'Kraft paper', 'Corrugated paper', 'Hologram Cardboard'],
    '7': ['White cardboard', 'Silver cardboard', 'Kraft paper', 'Corrugated paper', 'Hologram Cardboard'],
    '8': ['White cardboard', 'Silver cardboard', 'Kraft paper', 'Hologram Cardboard'],
    '9': ['White cardboard', 'Silver cardboard', 'Kraft paper', 'Corrugated paper', 'Hologram Cardboard'],
    '10': ['White cardboard', 'Silver cardboard', 'Kraft paper', 'Corrugated paper', 'Hologram Cardboard'],
    '11': ['Corrugated paper'],
    '12': ['White cardboard', 'Silver cardboard', 'Kraft paper', 'Hologram Cardboard'],
    '13': ['Rigid cardboard'],
    '14': ['Rigid cardboard'],
    '15': ['Rigid cardboard'],
  };

  // Define a common array for special features
  const commonSpecialFeatures = [
    'Light Proof',
    'Biodegradable',
    'Recyclable',
    'Clear Window',
    'Sturdy',
    'Reinforced'
  ];

  // Define printing features options
  const printingFeaturesOptions = [
    'Hologram Stamping',
    'Silver Ink',
    'Variable Data',
    'Hot Stamping',
    'Spot UV',
    'Invisible Black Light Ink',
    'Cold Stamping',
    'Raised Varnish',
    'Perforation',
    'Embossing',
    'Debossing'
  ];

  const materialToOptionsMap = {
    'White cardboard': {
      bottomClosure: ['Auto Lock', '1-3 Envelope Closure'],
      thickness: ['200g', '250g', '300g', '350g', '400g'],
      specialFeatures: commonSpecialFeatures,
      printingFinishes: ['Gloss/ Matte Lamination', 'Soft Touch', 'Gloss/ Matte Varnish', 'Spot UV', 'Embossing'],
      substrateType: ['Hologram', 'Metallic', 'Clear', 'White', 'Kraft'],
    },
    'Silver cardboard': {
      bottomClosure: ['Auto Lock', '1-3 Envelope Closure'],
      thickness: ['275g', '325g', '375g', '425g'],
      specialFeatures: commonSpecialFeatures,
      printingFinishes: ['Gloss/ Matte Lamination', 'Soft Touch', 'Gloss/ Matte Varnish', 'Spot UV', 'Embossing'],
      substrateType: ['Hologram', 'Metallic', 'Clear', 'White', 'Kraft'],
    },
    'Kraft paper': {
      bottomClosure: ['Auto Lock', '1-3 Envelope Closure'],
      thickness: ['200g', '250g', '300g', '350g'],
      specialFeatures: commonSpecialFeatures,
      printingFinishes: ['Gloss/ Matte Lamination', 'Gloss/ Matte Varnish', 'Spot UV'],
      substrateType: [],
    },
    'Corrugated paper': {
      bottomClosure: ['Auto Lock', '1-3 Envelope Closure'],
      thickness: ['250g/300g/350g White Cardboard + Corrugated Paper'],
      specialFeatures: commonSpecialFeatures,
      printingFinishes: ['Gloss/ Matte Lamination', 'Soft Touch', 'Gloss/ Matte Varnish', 'Spot UV', 'Embossing'],
      substrateType: ['Kraft', 'White'],
    },
    'Hologram Cardboard': {
      bottomClosure: ['Auto Lock', '1-3 Envelope Closure'],
      thickness: ['?'],
      specialFeatures: commonSpecialFeatures,
      printingFinishes: [],
      substrateType: [],
    },
    'Rigid cardboard': {
      bottomClosure: ['Magnetic Closure', 'Rigid Lid'],
      thickness: ['1mm', '2mm', '3mm'],
      specialFeatures: commonSpecialFeatures,
      printingFinishes: ['Gloss/ Matte Lamination', 'Soft Touch'],
      substrateType: ['White', 'Kraft'],
    },
  };

  const handleBoxTypeChange = (value) => {
    updateProduct('boxType', value);
    setSelectedMaterial(''); // Reset material selection when box type changes
  };

  const handleMaterialChange = (material) => {
    setSelectedMaterial(material);
    updateProduct('material', material);
  };

  const handleSpecialFeaturesChange = (feature) => {
    let currentFeatures = product.fields.specialFeatures ? product.fields.specialFeatures.split(',') : [];

    if (currentFeatures.includes(feature)) {
      currentFeatures = currentFeatures.filter(f => f !== feature);
    } else {
      currentFeatures.push(feature);
    }

    updateProduct('specialFeatures', currentFeatures.join(','));
  };

  const handlePrintingFeaturesChange = (feature) => {
    let features = product.fields.printingFeatures ? product.fields.printingFeatures.split(',') : [];
    if (features.includes(feature)) {
      features = features.filter(f => f !== feature);
    } else {
      features.push(feature);
    }
    updateProduct('printingFeatures', features.join(','));
  };

  const handleAddCustomPrintingFeature = () => {
    if (customPrintingFeature.trim()) {
      // Add to custom features list
      const newCustomFeatures = [...customFeatures, customPrintingFeature.trim()];
      setCustomFeatures(newCustomFeatures);

      // Add to product's printing features
      let features = product.fields.printingFeatures ? product.fields.printingFeatures.split(',') : [];
      features.push(customPrintingFeature.trim());
      updateProduct('printingFeatures', features.join(','));

      // Clear input
      setCustomPrintingFeature('');
    }
  };

  const renderMaterialOptions = () => {
    const boxType = product.fields.boxType;
    const materials = materialOptionsMap[boxType] || [];

    return (
      <div className="form-group">
        <div className="form-group">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">What type of products will be used in the boxes?</label>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={product.fields.productType || ''}
            onChange={(e) => updateProduct('productType', e.target.value)}
          />
        </div>

        <div className="form-group">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">What is the weight of the products in the boxes? (kg)</label>
          <input
            type="number"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={product.fields.productWeight || ''}
            onChange={(e) => updateProduct('productWeight', e.target.value)}
          />
        </div>

        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Material:</label>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          value={selectedMaterial || ''}
          onChange={(e) => handleMaterialChange(e.target.value)}
        >
          <option value="">Select Material</option>
          {materials.map((material, index) => (
            <option key={index} value={material}>{material}</option>
          ))}
        </select>
      </div>
    );
  };

  const renderOptionsBasedOnMaterial = () => {
    if (!selectedMaterial) return null;

    const options = materialToOptionsMap[selectedMaterial] || {};

    return (
      <>
        {/* Bottom Closure */}
        <div className="form-group">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Bottom Closure:</label>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={product.fields.bottomClosure || ''}
            onChange={(e) => updateProduct('bottomClosure', e.target.value)}
          >
            {options.bottomClosure?.map((closure, index) => (
              <option key={index} value={closure}>{closure}</option>
            ))}
          </select>
        </div>

        {/* Thickness */}
        <div className="form-group">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Thickness (g):</label>
          <input
            type="number"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={product.fields.thickness || 300}
            onChange={(e) => updateProduct('thickness', e.target.value)}
          />
        </div>

        {/* Height, Width, Depth */}
        <div className="form-group">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Height (mm):</label>
          <input
            type="number"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={product.fields.height || ''}
            onChange={(e) => updateProduct('height', e.target.value)}
          />
        </div>

        <div className="form-group">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Width (mm):</label>
          <input
            type="number"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={product.fields.width || ''}
            onChange={(e) => updateProduct('width', e.target.value)}
          />
        </div>

        <div className="form-group">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Depth (mm):</label>
          <input
            type="number"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={product.fields.depth || ''}
            onChange={(e) => updateProduct('depth', e.target.value)}
          />
        </div>

        {/* Special Features */}
        <div className="form-group">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Special Features:</label>
          {commonSpecialFeatures.map((feature, index) => (
            <label key={index} className="block tracking-wide text-sm font-bold leading-6 text-gray-900">
              <input
                type="checkbox"
                checked={product.fields.specialFeatures?.includes(feature) || false}
                onChange={() => handleSpecialFeaturesChange(feature)}
                className="mr-2"
              />
              {feature}
            </label>
          ))}
        </div>

        {/* Printing Finishes */}
        <div className="form-group">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Printing Finishes:</label>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={product.fields.printingFinishes || ''}
            onChange={(e) => updateProduct('printingFinishes', e.target.value)}
          >
            <option value="">Select Printing Finish</option>
            <option value="Gloss Lamination">Gloss Lamination</option>
            <option value="Matte Lamination">Matte Lamination</option>
            <option value="Gloss Varnish">Gloss Varnish</option>
            <option value="Matte Varnish">Matte Varnish</option>
            <option value="Soft Touch">Soft Touch</option>
          </select>
        </div>

        {/* Substrate Type */}
        {/* <div className="form-group">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Substrate Type:</label>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={product.fields.substrateType || ''}
            onChange={(e) => updateProduct('substrateType', e.target.value)}
          >
            {options.substrateType?.map((substrate, index) => (
              <option key={index} value={substrate}>{substrate}</option>
            ))}
          </select>
        </div> */}

        {/* Insert */}
        <div className="form-group">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Insert:</label>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={product.fields.insert || ''}
            onChange={(e) => updateProduct('insert', e.target.value)}
          >
            <option value="">Select Insert</option>
            <option value="Cardboard">Cardboard</option>
            <option value="Eva">Eva</option>
            <option value="Plastic Shell">Plastic Shell</option>
            <option value="Foam">Foam</option>
          </select>
        </div>

        {/* Printing Features */}
        {renderPrintingFeatures()}
      </>
    );
  };

  const renderPrintingFeatures = () => (
    <div className="form-group">
      <label className="block tracking-wide text-sm font-bold leading-6 mb-5 text-gray-900">Printing Features:</label>
      <div className="grid gap-2 grid-cols-2">
        {/* Predefined Features */}
        {printingFeaturesOptions.map((feature) => (
          <label key={feature} className="tracking-wide text-xs font-bold leading-6 text-gray-900 flex w-full flex-row" style={{ justifySelf: 'flex-start' }}>
            <input
              type="checkbox"
              checked={product.fields.printingFeatures?.split(',').includes(feature) || false}
              onChange={() => handlePrintingFeaturesChange(feature)}
              className="mr-2 text-gray-900 dark:text-gray-300"
              style={{ width: '15px', height: '15px' }}
            />
            {feature}
          </label>
        ))}

        {/* Custom Features */}
        {customFeatures.map((feature) => (
          <label key={feature} className="tracking-wide text-xs font-bold leading-6 text-gray-900 flex w-full flex-row" style={{ justifySelf: 'flex-start' }}>
            <input
              type="checkbox"
              checked={product.fields.printingFeatures?.split(',').includes(feature) || false}
              onChange={() => handlePrintingFeaturesChange(feature)}
              className="mr-2 text-gray-900 dark:text-gray-300"
              style={{ width: '15px', height: '15px' }}
            />
            {feature}
          </label>
        ))}
      </div>

      <div className="mt-4 flex gap-2">
        <input
          type="text"
          value={customPrintingFeature}
          onChange={(e) => setCustomPrintingFeature(e.target.value)}
          placeholder="Add custom printing feature"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        />
        <button
          type="button"
          onClick={handleAddCustomPrintingFeature}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          Add
        </button>
      </div>
    </div>
  );

  return (
    <div className="product-form">
      <div className="form-group">
        <img src="https://shipping-quote.labelslab.com/boxes.jpg" alt="Boxes Options" />
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Box Type:</label>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          value={product.fields.boxType || ''}
          onChange={(e) => handleBoxTypeChange(e.target.value)}
        >
          <option value="">Select Box Type</option>
          {boxTypeOptions.map((boxType, index) => (
            <option key={index} value={boxType.number}>
              #{boxType.number} - {boxType.name}
            </option>
          ))}
        </select>
      </div>

      {/* Render material options based on the box type */}
      {renderMaterialOptions()}

      {/* Render other fields based on the selected material */}
      {renderOptionsBasedOnMaterial()}
    </div>
  );
};

export default Boxes;
