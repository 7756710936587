import React, { useState } from 'react';

const Labels = ({ product, updateProduct }) => {
  const [core, setCore] = useState(product.fields.core || '');
  const [shape, setShape] = useState(product.fields.shape || 'Rectangle');
  const [variableData, setVariableData] = useState(product.fields.variableData || 'No Variable Data');
  const [perforations, setPerforations] = useState(product.fields.perforations || 'No');
  const [applyMethod, setApplyMethod] = useState(product.fields.applyMethod || 'Manual / By Hand Application');
  const [customPrintingFeature, setCustomPrintingFeature] = useState('');
  const [printingFeatures, setPrintingFeatures] = useState(product.fields.specialEffects ? product.fields.specialEffects.split(',') : []);

  const handleCoreChange = (e) => {
    const selectedCore = e.target.value;
    setCore(selectedCore);
    updateProduct('core', selectedCore);
  };

  const handleShapeChange = (e) => {
    const selectedShape = e.target.value;
    setShape(selectedShape);
    updateProduct('shape', selectedShape);
  };

  const handleVariableDataChange = (e) => {
    const selectedVariableData = e.target.value;
    setVariableData(selectedVariableData);
    updateProduct('variableData', selectedVariableData)
  };

  const handlePerforationsChange = (e) => {
    const selectedPerforations = e.target.value;
    setPerforations(selectedPerforations);
    updateProduct('perforations', selectedPerforations);
  };

  const handleApplyMethodChange = (e) => {
    const selectedApplyMethod = e.target.value;
    setApplyMethod(selectedApplyMethod);
    updateProduct('applyMethod', selectedApplyMethod);
  };

  const handleSizeChange = (dimension, value) => {
    const size = { ...product.fields.size, [dimension]: value };
    if (dimension === 'heightMM') {
      size.height = (value / 25.4).toFixed(2);
    }
    if (dimension === 'widthMM') {
      size.width = (value / 25.4).toFixed(2);
    }
    updateProduct('size', size);
  };

  const handleSpecialEffectsChange = (effect) => {
    let effects = product.fields.specialEffects ? product.fields.specialEffects.split(',') : [];
    if (effects.includes(effect)) {
      effects = effects.filter(e => e !== effect);
    } else {
      effects.push(effect);
    }
    updateProduct('specialEffects', effects.join(','));
  };

  const handleAddCustomPrintingFeature = () => {
    if (customPrintingFeature.trim()) {
      let effects = product.fields.specialEffects ? product.fields.specialEffects.split(',') : [];
      effects.push(customPrintingFeature.trim());
      updateProduct('specialEffects', effects.join(','));
      setCustomPrintingFeature('');
    }
  };

  return (
    <div className="product-form grid gap-2 grid-cols-2">
      <div className="form-group col-span-2">
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Core (optional):</label>
        <select
          name="core"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          value={core}
          onChange={handleCoreChange}
        >
          <option value="">Select a core</option>
          <option value="1">1</option>
          <option value="1.5">1.5</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </select>
      </div>

      <div className="col-span-2">
        <div className="form-group">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Shape:</label>
          <select
            name="shape"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={shape}
            onChange={handleShapeChange}
          >
            <option value="">Select a Shape</option>
            <option value="Circle">Circle</option>
            <option value="Oval">Oval</option>
            <option value="Rectangle">Rectangle</option>
            <option value="Square">Square</option>
            <option value="Special">Special</option>
          </select>
        </div>
      </div>

      <div className="form-group col-span-2">
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Material:</label>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          value={product.fields.material || ""}
          onChange={(e) => updateProduct('material', e.target.value)}
        >
          <option value="">Select Material</option>
          {[
            "Card Stock (no adhesive)",
            "Clear BOPP",
            "Clear Poly E (Flexible Substrate)",
            "Cosmo (Speckled) Hologram Bopp",
            "Counterfeit Evident “Void”",
            "Direct Thermal Paper",
            "Double Sided (Clear or White BOPP)",
            "Double Sided (Paper + Clear Poly)",
            "Double Sided Silver BOPP",
            "Egg Shell",
            "Estate Wine Paper (Off-White)",
            "Estate Wine Paper (Textured)",
            "Estate Wine Paper (White)",
            "Heavy Clear Sachet Film",
            "Heavy Silver Sachet Film",
            "Hologram Paper",
            "Light Silver Sachet Film",
            "Neon Paper (only black print)",
            "Paper (Silver)",
            "Paper (White)",
            "Peel Back Hologram Bopp",
            "Peel Back Silver Bopp",
            "Peel Back White Bopp",
            "Shimmer (Standard) Hologram Bopp",
            "Shimmer Hologram Paper",
            "Silver BOPP",
            "Silver Poly E (Flexible Substrate)",
            "Stacked Wine Paper + Clear Poly",
            "Thermal Paper",
            "White BOPP",
            "White Bopp (114 micron)",
            "White Poly E (Flexible Substrate)",
          ].map((material) => (
            <option key={material} value={material}>
              {material}
            </option>
          ))}
        </select>
      </div>


      <div className="form-group col-span-2">
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Finish:</label>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          value={product.fields.finish || ""}
          onChange={(e) => updateProduct('finish', e.target.value)}
        >
          <option value="">Select Finish</option>
          {[
            "Gloss Lamination",
            "Gloss Varnish",
            "Hologram Lamination",
            "Matte Lamination",
            "Matte Varnish",
            "No UV Finish",
            "Semi Gloss Varnish"
          ].map((finish) => (
            <option key={finish} value={finish}>
              {finish}
            </option>
          ))}
        </select>
      </div>


      <div className="form-group col-span-2">
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Label Application Method:</label>
        <select
          name="apply_method"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          value={applyMethod}
          onChange={handleApplyMethodChange}
        >
          <option value="">Select Label Application Method</option>
          <option value="Automatic / Machine Application">Automatic / Machine Application</option>
          <option value="Manual / By Hand Application">Manual / By Hand Application</option>
        </select>
      </div>

      <div className="col-span-2 mb-2">
        <label className="block tracking-wide text-sm font-bold leading-6 mb-5 text-gray-900">Printing Features:</label>
        <div className="grid gap-2 grid-cols-2">
          {[
            "Clear Stamping",
            "Cold Stamping 2 Colors",
            "Embossing",
            "Foil Stamping",
            "Hologram Stamping",
            "Hot Stamping 2 Colors",
            "Invisible Black Light Ink",
            "Metallics",
            "Perforation",
            "Print on Liner",
            "Raised Varnish",
            "Silver Ink",
            "Soft Touch Finish",
            "Spot UV",
            "Thermal Varnish",
            "UV Ink",
            "Variable Data",
            'Pre-Cut',
            'Scratch-off',
            'Easy removable adhesive',
            'Permanent adhesive',
            'Selective adhesive',
            'Refrigerated adhesive'
          ].map((effect) => (
            <label key={effect} className="tracking-wide text-xs font-bold leading-6 text-gray-900 flex w-full flex-row" style={{ justifySelf: 'flex-start' }}>
              <input
                type="checkbox"
                checked={product.fields.specialEffects?.split(',').includes(effect) || false}
                onChange={() => handleSpecialEffectsChange(effect)}
                className="mr-2 text-gray-900 dark:text-gray-300"
                style={{ width: '15px', height: '15px' }}
              />
              {effect}
            </label>
          ))}
        </div>

        <div className="mt-4 flex gap-2">
          <input
            type="text"
            value={customPrintingFeature}
            onChange={(e) => setCustomPrintingFeature(e.target.value)}
            placeholder="Add custom printing feature"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
          <button
            type="button"
            onClick={handleAddCustomPrintingFeature}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Add
          </button>
        </div>
      </div>

      <div className="form-group col-span-2">
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Rolling Direction:</label>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          value={product.fields.rollingDirection || ""}
          onChange={(e) => updateProduct('rollingDirection', e.target.value)}
        >
          <option value="">Select Rolling Direction</option>
          {[
            '1',
            '2',
            '3',
            '4',
          ].map((direction) => (
            <option key={direction} value={direction}>
              {direction}
            </option>
          ))}
        </select>
      </div>


      <div className="form-group">
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Height: (in mm)</label>
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          type="number"
          value={product.fields.size?.heightMM || ""}
          onChange={(e) => handleSizeChange('heightMM', e.target.value)}
          placeholder="Height in mm"
        />
      </div>

      <div className="form-group">
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Width: (in mm)</label>
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          type="number"
          value={product.fields.size?.widthMM || ""}
          onChange={(e) => handleSizeChange('widthMM', e.target.value)}
          placeholder="Width in mm"
        />
      </div>

      <div className="form-group">
        <label className="block tracking-wide text-sm font-bold leading-6 text-red-500">Height: (in Inches)</label>
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          type="number"
          value={product.fields.size?.height || ""}
          readOnly
          placeholder="Height"
        />
      </div>

      <div className="form-group">
        <label className="block tracking-wide text-sm font-bold leading-6 text-red-500">Width: (in Inches)</label>
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          type="number"
          value={product.fields.size?.width || ""}
          readOnly
          placeholder="Width"
        />
      </div>
    </div>
  );
};

export default Labels;