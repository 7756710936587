import React, { useState, useEffect } from 'react';

const Bags = ({ product, updateProduct }) => {
  // Initialize state for bagType and selectedMaterial
  const [bagType, setBagType] = useState(product.fields.bagType || '');
  const [selectedMaterial, setSelectedMaterial] = useState(product.fields.material || '');
  const [bagSpecifications, setBagSpecifications] = useState(product.fields.bagSpecifications || []);
  const [width, setWidth] = useState(product.fields.width || '');
  const [height, setHeight] = useState(product.fields.height || '');
  const [gusset, setGusset] = useState(product.fields.gusset || '');
  const [gussetType, setGussetType] = useState(product.fields.gussetType || '');
  const [closureType, setClosureType] = useState(product.fields.closureType || '');
  const [zipperType, setZipperType] = useState(product.fields.zipperType || '');
  const [printingFeatures, setPrintingFeatures] = useState(product.fields.printingFeatures || []);
  const [customMaterial, setCustomMaterial] = useState(product.fields.customMaterial || '');

  useEffect(() => {
    // Update product.fields.bagType whenever bagType state changes
    updateProduct('bagType', bagType);
  }, [bagType, updateProduct]);

  useEffect(() => {
    // Update product.fields.bagSpecifications whenever bagSpecifications state changes
    updateProduct('bagSpecifications', bagSpecifications);
  }, [bagSpecifications, updateProduct]);

  useEffect(() => {
    // Update product.fields.width whenever width state changes
    updateProduct('width', width);
  }, [width, updateProduct]);

  useEffect(() => {
    // Update product.fields.height whenever height state changes
    updateProduct('height', height);
  }, [height, updateProduct]);

  useEffect(() => {
    // Update product.fields.gusset whenever gusset state changes
    updateProduct('gusset', gusset);
  }, [gusset, updateProduct]);

  useEffect(() => {
    // Update product.fields.gussetType whenever gussetType state changes
    updateProduct('gussetType', gussetType);
  }, [gussetType, updateProduct]);

  useEffect(() => {
    // Update product.fields.closureType whenever closureType state changes
    updateProduct('closureType', closureType);
  }, [closureType, updateProduct]);

  useEffect(() => {
    // Update product.fields.zipperType whenever zipperType state changes
    updateProduct('zipperType', zipperType);
  }, [zipperType, updateProduct]);

  useEffect(() => {
    // Update product.fields.printingFeatures whenever printingFeatures state changes
    updateProduct('printingFeatures', printingFeatures);
  }, [printingFeatures, updateProduct]);

  // Bag types (static, not affecting other options)
  const bagTypes = [
    { value: '1', label: '3 Side Sealed' },
    { value: '2', label: 'Stand Up with Bottom Gusset Without Zipper' },
    { value: '3', label: '3 Side Sealed with Zipper' },
    { value: '4', label: 'Stand Up with Bottom Gusset and Zipper' },
    { value: '5', label: 'Back Side Sealed' },
    { value: '6', label: 'Side Gusset Pouch' },
    { value: '7', label: 'Flat Bottom with Zipper' },
    { value: '8', label: 'Special Shape Bag' },
    { value: '9', label: 'Roll Film' },
    { value: '10', label: 'Spout Bag' }
  ];

  // Material options
  const materialOptions = [
    "PET / VMPET / PE",
    "PET / PE",
    "MATTE OPP / VMPET / PE",
    "PET / AL / PE",
    "PET / LDPE",
    "ALOX",
    "KRAFT PAPER / PE",
    "KRAFT PAPER / VMPET / PE",
    "PE / PE",
    "KRAFT PAPER / PLA",
    "With clear window: Matte opp/PET/PE",
    "Other"
  ];

  // Special effects options
  const printingFeaturesOptions = [
    "Hologram Stamping",
    "Silver Ink",
    "Variable Data",
    "Hot Stamping",
    "Spot UV",
    "Invisible Black Light Ink",
    "Cold Stamping",
    "Raised Varnish",
    // "Perforation"
  ];

  // Mapping of materials to their specific options
  const materialToOptionsMap = {
    "PET / VMPET / PE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: ["Hologram", "Metallic", "Clear", "White", "Kraft"]
    },
    "PET / PE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: ["Hologram", "Metallic", "Clear", "White", "Kraft"]
    },
    "MATTE OPP / VMPET / PE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: ["Hologram", "Metallic", "Clear", "White", "Kraft"]
    },
    "PET / AL / PE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: ["Hologram", "Metallic", "Clear", "White", "Kraft"]
    },
    "PET / LDPE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: ["Hologram", "Metallic", "Clear", "White", "Kraft"]
    },
    "ALOX": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Thin Transparent Aluminum Oxide Coating", "Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: [],
      substrateType: []
    },
    "KRAFT PAPER / PE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: []
    },
    "KRAFT PAPER / VMPET / PE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: []
    },
    "PE / PE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: []
    },
    "KRAFT PAPER / PLA": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: []
    },
    "With clear window: Matte opp/PET/PE": {
      microns: ["90", "100", "110", "120"],
      specialFeatures: ["Smell Proof", "Light Proof", "Microwaveable", "Moisture Resistant", "Air Proof", "Cold Temperature Proof", "Heat Proof"],
      printingFinishes: ["Gloss Lamination", "Matte Lamination", "Gloss Varnish", "Matte Varnish", "Soft Touch", "Hologram Lamination"],
      substrateType: ["Clear", "White"]
    }
  };

  // Handle material change
  const handleMaterialChange = (e) => {
    const material = e.target.value;
    setSelectedMaterial(material);
    
    if (material === 'Other') {
      // If "Other" is selected, don't update the material field yet
      updateProduct('material', '');
    } else {
      // For pre-defined materials, update as normal
      updateProduct('material', material);
      setCustomMaterial(''); // Clear custom material when selecting a predefined option
    }
    
    // Reset dependent fields
    updateProduct('microns', '');
    updateProduct('layers', '');
    updateProduct('specialFeatures', []);
    updateProduct('printingFinishes', '');
    updateProduct('substrateType', '');
  };

  // Add handler for custom material input
  const handleCustomMaterialChange = (e) => {
    const value = e.target.value;
    setCustomMaterial(value);
    updateProduct('material', value);
    updateProduct('customMaterial', value);
  };

  const handleBagTypeChange = (e) => {
    const value = e.target.value;
    setBagType(value);
    updateProduct('bagType', value);
  
    if (value === '10') {
      // Pre-select Spout for bagType 10
      setClosureType('Zipper');
      updateProduct('closureType', 'Zipper');
      setZipperType('Spout');
      updateProduct('zipperType', 'Spout');
    } else if (['1', '2', '5', '9'].includes(value)) {
      // Clear zipper and closure type for restricted bag types
      setClosureType('');
      setZipperType('');
      updateProduct('closureType', '');
      updateProduct('zipperType', '');
    }
  };
  

  // Handle bag features change
  const handleBagFeaturesChange = (option) => {
    let updatedFeatures = [...bagSpecifications];
    if (updatedFeatures.includes(option)) {
      updatedFeatures = updatedFeatures.filter(feature => feature !== option);
    } else {
      updatedFeatures.push(option);
    }
    setBagSpecifications(updatedFeatures);
  };

  // Handle printing features change
  const handlePrintingFeaturesChange = (feature) => {
    let updatedFeatures = [...printingFeatures];
    if (updatedFeatures.includes(feature)) {
      updatedFeatures = updatedFeatures.filter(f => f !== feature);
    } else {
      updatedFeatures.push(feature);
    }
    setPrintingFeatures(updatedFeatures);
    updateProduct('printingFeatures', updatedFeatures);
  };

  // Handle gusset change
  const handleGussetChange = (e) => {
    const value = e.target.value;
    setGusset(value);
    updateProduct('gusset', value === 'yes' ? 'yes' : 'no');
    if (value === 'no') {
      setGussetType('');
      updateProduct('gussetType', '');
    }
  };

  // Handle closure type change
  const handleClosureTypeChange = (e) => {
    const value = e.target.value;
    setClosureType(value);
    updateProduct('closureType', value);
    if (value !== 'Zipper') {
      setZipperType('');
      updateProduct('zipperType', '');
    }
  };

  // Render conditional fields based on selected material
  const renderConditionalFields = () => {
    if (!selectedMaterial) return null;
    
    // Get options with a default empty object if undefined
    const options = materialToOptionsMap[selectedMaterial] || {
      microns: [],
      specialFeatures: [],
      printingFinishes: [],
      substrateType: []
    };

    return (
      <>
        {/* Microns */}
        <div className="form-group">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Microns:</label>
          <input
            type="number"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
            value={product.fields.microns || '120'} 
            min={1}
            onBlur={(e) => {
              const newMicrons = e.target.value;
              if (!newMicrons || newMicrons <= 0) {
                alert("Error: Please set a valid microns value.");
                e.target.value = product.fields.microns || '120';
                return;
              }
              if (window.confirm("Are you sure you want to change the microns value?")) {
                updateProduct('microns', newMicrons);
              } else {
                e.target.value = product.fields.microns || '120';
              }
            }}
            onChange={(e) => {
              updateProduct('microns', e.target.value);
            }}
          />
        </div>

        {/* Printing Finishes */}
        {Array.isArray(options.printingFinishes) && options.printingFinishes.length > 0 && (
          <div className="form-group">
            <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Printing Finishes:</label>
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
              value={product.fields.printingFinishes || ''}
              onChange={(e) => updateProduct('printingFinishes', e.target.value)}
            >
              <option value="">Select Printing Finish</option>
              {options.printingFinishes.map((finish, index) => (
                <option key={index} value={finish}>{finish}</option>
              ))}
            </select>
          </div>
        )}

        {/* Substrate Type */}
        {Array.isArray(options.substrateType) && options.substrateType.length > 0 && (
          <div className="form-group">
            <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Substrate Type:</label>
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
              value={product.fields.substrateType || ''}
              onChange={(e) => updateProduct('substrateType', e.target.value)}
            >
              <option value="">Select Substrate Type</option>
              {options.substrateType.map((substrate, index) => (
                <option key={index} value={substrate}>{substrate}</option>
              ))}
            </select>
          </div>
        )}
      </>
    );
  };

  // Render Loading Side dropdown
  const renderLoadingSide = () => (
    <div className="form-group">
      <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Loading Side:</label>
      <select
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
        value={product.fields.loadingSide || ''}
        onChange={(e) => updateProduct('loadingSide', e.target.value)}
      >
        <option value="">Select Loading Side</option>
        <option value="top">Top</option>
        <option value="bottom">Bottom</option>
        <option value="left">Left Side</option>
        <option value="right">Right Side</option>
      </select>
    </div>
  );

  // Render Gusset input and options
  const renderGussetOptions = () => {
    // Hide gusset options for bag types 1, 5, 9
    if (['1', '5', '9'].includes(bagType)) {
      return null;
    }

    return (
      <div className="form-group">
        {/* Gusset Size Input */}
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Gusset (in mm):</label>
        <input
          type="number"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
          value={gusset}
          onChange={(e) => setGusset(e.target.value)}
        />
        {/* Gusset Type Dropdown */}
        <div className="form-group mt-2">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Gusset Type:</label>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
            value={gussetType}
            onChange={(e) => {
              setGussetType(e.target.value);
              updateProduct('gussetType', e.target.value);
            }}
          >
            <option value="">Select Gusset Type</option>
            <option value="Doyan">Doyan</option>
            <option value="K-Seal">K-Seal</option>
          </select>
        </div>
      </div>
    );
  };

  // Define renderBagSpecifications if needed
  const renderBagSpecifications = () => {
    // Hide bag specifications for bag types 1, 5, 9
    if (['1', '5', '9'].includes(bagType)) {
      return null;
    }

    return (
      <div className="form-group">
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Bag Features:</label>
        <div className="grid gap-2 grid-cols-2">
          {['Rounded Corners', 'Straight Corners', 'Valve', 'Punch Hole', 'Tear Notch', 'Sombrero Hole'].map(option => (
            <label key={option} className="flex items-center">
              <input
                type="checkbox"
                checked={bagSpecifications.includes(option)}
                onChange={() => handleBagFeaturesChange(option)}
                className="mr-2"
              />
              {option}
            </label>
          ))}
        </div>
      </div>
    );
  };

  // Render Closure Type options
// Render Closure Type Options
const renderClosureTypeOptions = () => {
  // Hide everything for bag types 1, 2, 5, 9 (and handle type 10 separately)
  const shouldHideEntireFormGroup = ['1', '2', '5', '9'].includes(bagType);

  if (shouldHideEntireFormGroup) {
    // Return nothing if the entire form group should be hidden
    return null;
  }

  return (
    <div className="form-group">
      {/* Handle Bag Type 10 separately */}
      {bagType === '10' ? (
        <div className="form-group mt-2">
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Zipper Type:</label>
          <div className="flex items-center">
            <label className="flex items-center">
              <input
                type="radio"
                name="zipperType"
                value="Spout"
                checked={zipperType === 'Spout'}
                onChange={() => {
                  setZipperType('Spout');
                  updateProduct('zipperType', 'Spout');
                }}
                className="mr-2"
              />
              Spout
            </label>
          </div>
        </div>
      ) : (
        // Default behavior for other bag types
        <>
          <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Closure Type:</label>
          <div className="flex items-center space-x-4">
            <label className="flex items-center">
              <input
                type="radio"
                name="closureType"
                value="Tin Tie"
                checked={closureType === 'Tin Tie'}
                onChange={(e) => {
                  setClosureType(e.target.value);
                  updateProduct('closureType', e.target.value);
                  setZipperType(''); // Reset zipperType when changing closure type
                }}
                className="mr-2"
              />
              Tin Tie
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="closureType"
                value="Zipper"
                checked={closureType === 'Zipper'}
                onChange={(e) => {
                  setClosureType(e.target.value);
                  updateProduct('closureType', e.target.value);
                }}
                className="mr-2"
              />
              Zipper
            </label>
          </div>

          {/* Handle Zipper Type Logic */}
          {closureType === 'Zipper' && (
            <div className="form-group mt-2">
              <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Zipper Type:</label>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                value={zipperType}
                onChange={(e) => {
                  setZipperType(e.target.value);
                  updateProduct('zipperType', e.target.value);
                }}
              >
                <option value="">Select Zipper Type</option>
                <option value="Regular Zipper">Regular Zipper</option>
                <option value="CR Zipper">CR Zipper</option>
                <option value="Slider Zipper">Slider Zipper</option>
                <option value="Double Zipper">Double Zipper</option>
                <option value="Pull Tab Zipper">Pull Tab Zipper</option>
                <option value="Velcro Zipper">Velcro Zipper</option>
                <option value="Powder Zipper">Powder Zipper</option>
              </select>
            </div>
          )}
        </>
      )}
    </div>
  );
};  
  

  // Render Printing Features options
  const renderPrintingFeatures = () => (
    <div className="form-group">
      <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Printing Features:</label>
      <div className="grid gap-2 grid-cols-2">
        {printingFeaturesOptions.map(feature => (
          <label key={feature} className="flex items-center">
            <input
              type="checkbox"
              checked={printingFeatures.includes(feature)}
              onChange={() => handlePrintingFeaturesChange(feature)}
              className="mr-2"
            />
            {feature}
          </label>
        ))}
      </div>
    </div>
  );

  return (
    <div className="product-form">
      {/* Bag Type */}
      <div className="form-group">
        <img src="https://shipping-quote.labelslab.com/bags.png" alt="BagsImage" />
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Select Bag Type:</label>
        <select
  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
  value={bagType}
  onChange={handleBagTypeChange}
>
  <option value="">Select Bag Type</option>
  {bagTypes.map((type) => (
    <option key={type.value} value={type.value}>{`${type.value} - ${type.label}`}</option>
  ))}
</select>

      </div>

      {/* Width */}
      <div className="form-group">
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Width (in mm):</label>
        <input
          type="number"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
          value={width}
          onChange={(e) => setWidth(e.target.value)}
        />
      </div>

      {/* Height */}
      <div className="form-group">
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Height (in mm):</label>
        <input
          type="number"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
        />
      </div>

      {/* Gusset Options */}
      {renderGussetOptions()}

      {/* Material */}
      <div className="form-group">
        <label className="block tracking-wide text-sm font-bold leading-6 text-gray-900">Material:</label>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
          value={selectedMaterial}
          onChange={handleMaterialChange}
        >
          <option value="">Select Material</option>
          {materialOptions.map((material, index) => (
            <option key={index} value={material}>{material}</option>
          ))}
        </select>
        
        {selectedMaterial === 'Other' && (
          <div className="mt-2">
            <input
              type="text"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
              value={customMaterial}
              onChange={handleCustomMaterialChange}
              placeholder="Enter custom material"
            />
          </div>
        )}
      </div>

      {/* Closure Type Options */}
      {renderClosureTypeOptions()}

      {/* Conditional Fields Based on Material */}
      {renderConditionalFields()}

      {/* Additional Fields */}
      {renderBagSpecifications()}

      {/* Loading Side */}    
      {renderLoadingSide()}

      {/* Printing Features */}
      {renderPrintingFeatures()}

    </div>
  );
};

export default Bags;
